@import '../../styles/_shared';

.OnboardingWizard {
  &__Wrapper {
    background: $color__white;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
  }

  &__LoadingOverlay {
    @include util__absolute-coverage;
    z-index: $z__set--exposed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color__white, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__Header {
    padding: 30px 30px 0;

    @include xs {
      padding: 15px 15px 0;
    }
  }

  &__Footer {
    padding: 0 30px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include xs {
      padding: 30px 15px;
    }
  }

  &__RequiredRemark {
    @include style__text--small;
    padding: 30px 30px 0;
    color: $color__gray--text;
    max-width: 800px;
    margin: 0 auto;

    @include xs {
      padding: 15px;
    }
  }
}
