@import '../../styles/_shared';

.Footer {
  padding: 40px 0;

  &__Inner {
    display: flex;
    flex-flow: column nowrap;

    @include lg {
      flex-flow: row wrap;
    }
  }

  &__ToTop {
    margin: 0 20px 0 0;

    @include lg {
      margin: 0 40px 0 0;
    }
  }

  &__Menu {
    display: inline-flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 40px 0 0;

    @include lg {
      margin: 0;
    }

    & > * {
      &:not(:last-child) {
        margin: 0 20px 0 0;

        @include lg {
          margin: 0 40px 0 0;
        }
      }
    }
  }

  &__SocialMedia {
    display: inline-flex;
    flex-flow: row wrap;
    margin: 34px -8px -8px;

    @include lg {
      margin: -8px -8px -8px auto;
    }

    & > * {
      margin: 8px;
    }
  }
}
