@import '../../../styles/shared';

.TourCallout {
  background-color: $color__white;
  border-radius: 8px;
  min-width: 335px;
  position: relative;
  display: flex;
  flex-direction: column;

  @include md-down {
    min-width: 260px;
  }

  &--abort {
    min-width: 300px;

    @include md-down {
      min-width: 260px;
    }
  }

  &--below {
    margin-top: 8px;
  }

  &--below &__Head {
    position: absolute;
    top: -12px;
    margin-right: 20px;
    margin-left: 20px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent $color__white transparent;
  }

  &--above {
    margin-bottom: 8px;
  }

  &--above &__Head {
    position: absolute;
    bottom: -12px;
    margin-right: 20px;
    margin-left: 20px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 10px 0 10px;
    border-color: $color__white transparent transparent transparent;
  }

  &__Head--start {
    align-self: start;
  }

  &__Head--center {
    align-self: center;
  }

  &__Head--end {
    align-self: end;
  }


  &__Close {
    position: absolute;
    right: 20px;
    top: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    font-size: 0;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    @include noTouch {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__Content {
    position: relative;
    @include style__text--small;
    padding: 20px 47px 24px 20px;
  }

  &--abort &__Content {
    @include style__text--small;
    padding: 30px 30px 24px 30px;
  }

  &__Action {
    border-top: 1px solid $color__gray--bg;
    padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > span {
      @include font__normal;
      color: $color__gray--text;
      font-size: 14px;
      line-height: 26px;
    }

    & > button {
      padding: 8px;
      max-width: 123px;

      & > span {
        @include font__bold;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  &--abort &__Action {
    padding: 15px 30px 15px 30px;

    & > button {
      max-width: unset;
    }
  }

}
