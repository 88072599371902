@import '../../styles/shared';

.CampaignHero {
  &__Back {
    position: absolute;
    left: 0;
    top: 60px;

    @include xs {
      top: 30px;
    }
  }

  &__Title {
    @include style__h1;
    color: $color__black--text;

    &--Inverted {
      color: $color__white;
    }
  }

  &__Excerpt {
    @include style__h4;
    color: $color__black--text;
    margin-top: 20px;

    &--Inverted {
      color: $color__white;
    }
  }

  &__Button {
    margin-top: 30px;

    @include sm-down {
      display: none;
    }
  }
}
