@import './inputs';

.NumericPicker {
  @include Input__Wrapper();
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Label {
    @include Input__Label();
  }

  &__Value {
    @include Input__Textbox();

    &--Null {
      color: $color__gray--text;
    }
  }

  &__Buttons {
    display: flex;
    align-items: center;
    font-size: 0;
    flex: none;
  }

  &__Button {
    &--Plus {
      margin-left: 15px;
    }
  }

  &--Disabled &__Value,
  &--Readonly &__Value {
    @include Input__Textbox(true);
  }

  &__Validation {
    @include Input__Validation();

    &--Visible {
      @include Input__Validation(true);
    }
  }

  &__Readonly {
    @include Input__ReadonlyFlag();
  }
}
