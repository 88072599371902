@import '../../styles/_shared';

.FAQs {
  padding: 125px 0;

  @include md-down {
    padding: 80px 0;
  }

  @include xs {
    padding: 40px 0;
  }

  .AccordionItem__Content {
    padding-right: 10px;
  }

  &__Title {
    @include style__h2;
    margin-bottom: 20px;
  }

  &__Outro {
    margin-top: 30px;
  }
}
