@import '../../styles/_shared';

.IndexFilter {
  margin-top: 41px;
  display: flex;
  gap: 20px;
  align-items: stretch;

  flex-direction: row;

  @include md-down {
    flex-direction: column;
  }

  &__Categories {
    width: 305px;
    height: 52px;

    @include md-down {
      width: 100%;
    }
  }

  &__Spacer {
    flex: 1;
    @include md-down {
      display: none;
    }
  }

  &__Locations {
    width: 387px;
    height: 52px;

    @include md-down {
      width: 100%;
    }
  }

  &__Find {
    width: 164px;
    height: 52px;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    & button {
      @include md-down {
        max-width: unset;
      }
    }


    @include md-down {
      width: 100%;
    }
  }
}
