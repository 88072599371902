@import 'fonts';

/* COLORS */
$color__black: #000000;
$color__black--text: #212529;
$color__blue: #143c7f;
$color__gray--bg: #f5f5f5;
$color__gray--border: #b3b3b3;
$color__gray--border-light: #e0e0e0;
$color__gray--strong: #616161;
$color__gray--text: #9e9e9e;
$color__orange: #f39100;
$color__orange--hover: #f8a01b;
$color__white: #ffffff;

/* GRADIENTS */
$gradient__orange--horizontal: linear-gradient(to right, #f8522a 0%, #f39100 50%, #f8be2a 100%);

/* FONT STYLES */
@mixin style__h1() {
  @include font__bold;
  font-size: 54px;
  line-height: 62px;
  padding: 0;
  margin: 0;

  @include md-down {
    font-size: 42px;
    line-height: 48px;
  }

  @include xs {
    font-size: 36px;
    line-height: 42px;
  }
}

@mixin style__h2() {
  @include font__bold;
  font-size: 42px;
  line-height: 54px;
  padding: 0;
  margin: 0;

  @include md-down {
    font-size: 34px;
    line-height: 44px;
  }

  @include xs {
    font-size: 24px;
    line-height: 30px;
  }
}

@mixin style__h3() {
  @include font__bold;
  font-size: 26px;
  line-height: 32px;
  padding: 0;
  margin: 0;

  @include xs {
    font-size: 20px;
    line-height: 24px;
  }
}

@mixin style__h4() {
  @include font__bold;
  font-size: 20px;
  line-height: 28px;
  padding: 0;
  margin: 0;

  @include xs {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin style__text() {
  @include font__normal;
  font-size: 16px;
  line-height: 26px;
  padding: 0;
  margin: 0;

  @include xs {
    font-size: 14px;
    line-height: 22px;
  }
}

@mixin style__text--big() {
  @include font__normal;
  font-size: 18px;
  line-height: 26px;
  padding: 0;
  margin: 0;

  @include xs {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin style__text--small() {
  @include font__normal;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  margin: 0;

  @include xs {
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin style__text--button() {
  @include font__bold;
  font-size: 16px;
  line-height: 16px;

  @include xs {
    font-size: 14px;
    line-height: 14px;
  }
}

@mixin style__text--breadcrumbs() {
  @include font__normal;
  font-size: 12px;
  line-height: 16px;
}

@mixin style__text--label() {
  @include font__normal;
  font-size: 12px;
  line-height: 12px;
}

@mixin style__text--tooltip() {
  @include font__normal;
  font-size: 12px;
  line-height: 20px;
}

/* UTILITIES */
@mixin util__absolute-coverage() {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* EASINGS */

// Ease In
$ease__in--sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease__in--quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease__in--cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease__in--quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease__in--quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease__in--expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease__in--circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease__in--back: cubic-bezier(0.6, -0.28, 0.735, 0.045);

// Ease Out
$ease__out--sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease__out--quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease__out--cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease__out--quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease__out--quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease__out--expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease__out--circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease__out--back: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// Ease In-Out
$ease__in-out--sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease__in-out--quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease__in-out--cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease__in-out--quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease__in-out--quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease__in-out--expo: cubic-bezier(1, 0, 0, 1);
$ease__in-out--circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease__in-out--back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

/* TRANSITIONS */

@mixin transition__fade-in($duration, $delay) {
  transition: all $duration $ease__out--quad $delay;
}

@mixin transition__fade-out($duration, $delay) {
  transition: all $duration $ease__in--quad $delay;
}

@mixin transition__bounce-in($duration, $delay) {
  transition: all $duration $ease__out--back $delay;
}

@mixin transition__bounce-out($duration, $delay) {
  transition: all $duration $ease__in--back $delay;
}

$dt__quick: 500ms;


/* Z-INDICES */
$z__revert: -1;
$z__lower--shallow: -5;
$z__raise--low: 5;
$z__raise--medium: 10;
$z__raise--high: 20;
$z__set--intermediate: 50;
$z__set--exposed: 1000;
$z__set--between--overlay: 5000;
$z__set--overlay: 10000;
$z__set--top: 100000;

