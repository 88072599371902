@import '../../styles/_shared';

html {
  scroll-padding-top: 138px;
}

.Header {
  z-index: $z__set--exposed;
  position: sticky;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-out;
  background: $color__white;

  &--Scrolled {
    transform: translateY(-30px);

    @include sm-up {
      transform: translateY(-40px);
    }
  }

  &__Meta {
    height: 30px;
    margin: 0 -15px;
    background: $color__gray--bg;

    @include sm-up {
      height: 40px;
      margin: 0;
    }
  }

  &__MetaMenu {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }

  &__MetaSpacer {
    flex: 1 1 auto;
  }

  &__MetaLink {
    @include style__text--small;
    box-sizing: border-box;
    display: inline-block;
    height: 30px;
    padding: 6px 15px;
    color: $color__gray--text;
    transition: color 0.1s ease-in-out;
    text-decoration: none;

    @include sm-up {
      height: 40px;
      padding: 9px 15px;
    }

    &:hover {
      color: $color__orange;
    }

    &--Active {
      background: $color__white;
      color: $color__black--text;
    }

    // For Icons
    svg {
      margin-left: 5px;
      vertical-align: -2px;

      @include xs {
        vertical-align: -4px;
      }
    }

    svg path {
      fill: $color__gray--text;
      transition: fill 0.1s ease-in-out;
    }

    &:hover svg path {
      fill: $color__orange;
    }
  }

  &__MetaSignOutText {
    @include xs {
      display: none;
    }
  }

  &__Main {
    padding: 20px 0 10px;
    background: $color__white;

    @include sm-up {
      padding: 20px 0;
    }
  }

  &__MainWrapper {
    display: flex;
    justify-content: stretch;
    align-items: center;

    @include xs {
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__EvmLogoLink {
    font-size: 0;
    margin: 0 30px 0 0;

    @include md-up {
      margin: 0 40px 0 0;
    }
  }

  &__EvmLogoImage {
    width: 57px;
    height: 24px;

    @include md-up {
      width: 90px;
      height: 38px;
    }
  }

  &__Menu {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 40px;

    @include sm-down {
      gap: 15px;
    }

    @include xs {
      order: 3;
      flex: none;
      width: 100%;
      margin-top: 10px;
    }
  }

  &__EnBoLogoLink {
    font-size: 0;
  }

  &__EnBoLogoImage {
    width: 88px;
    height: 30px;

    @include md-up {
      width: 100px;
      height: 34px;
    }
  }
}
