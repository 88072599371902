@import "../../styles/shared";

.Box__DisableBody {
  pointer-events: none;
}

.Box {
  position: relative;
  transition: z-index $dt__quick ease-in-out;

  &--row {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  &--col {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &--elevate {
    z-index: $z__set--intermediate;
  }

  &--enable {
    pointer-events: auto;
  }
}
