@import '../../styles/_shared';

.Bookmarks {
  &__Empty {
    padding: 60px 30% 60px 0;

    @include sm {
      padding: 60px 0;
    }

    @include xs {
      padding: 30px 0;
    }
  }

  &__EmptyHeadline {
    @include style__h2;
    margin-bottom: 30px;
  }

  &__Back {
    margin-top: 30px;
  }
}
