@import '../../styles/shared';
@import './inputs/inputs';

.LocationSelect {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  & label {
    flex: 1;
    position: relative;

    & > svg {
      cursor: pointer;
    }
  }

  &__Pin, &__Acquiring {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    padding: 12px;
  }

  &__Pin {
    & * {
      transition: fill 0.3s ease-in-out;
    }

    &:hover * {
      fill: $color__orange;
    }
  }

  &__Acquiring {
  }

  &__Clear {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    padding: 12px;
  }

  & input {
    @include Input__Textbox();

    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 15px 36px 15px 44px;
    border: 1px solid $color__gray--border-light;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    background-color: $color__white;
    font-size: 16px;

    &:focus {
      outline: $color__orange;
    }

    &[data-has-geo='true']::placeholder {
      color: $color__black;
    }
  }

  .Dropdown {
    width: 140px;

    &__Header {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

}
