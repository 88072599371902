@import '../../styles/_shared';

.QrCode {
  position: relative;
  &__Partner {
    @include style__text--small;
    @include font__bold;
    color: $color__gray--text;
    margin-bottom: 5px;
  }

  &__Title {
    @include style__h3;
    color: $color__black--text;
    padding-right: 50px; // Account for closing button
    margin-bottom: 40px;
  }

  &__Columns {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    @include xs {
      flex-direction: column;
    }
  }

  &__Code {
    width: 50%;
    flex: 1 1 auto;
    padding-right: 20px;

    @include xs {
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px;
    }
  }

  &__Image {
    width: 100%;
    height: auto;
    aspect-ratio: 1;

    @include xs {
      max-width: 250px;
      margin: 0 auto;
      display: block;
    }
  }

  &__Alias {
    width: 50%;
    flex: 1 1 auto;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    @include xs {
      width: 100%;
      padding-left: 0;
    }
  }

  &__TipWrapper,
  &__AliasCodeWrapper {
    height: 50%;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;

    @include xs {
      height: auto;
      padding: 10px 5px;
    }
  }

  &__TipWrapper {
    background: $color__gray--bg;
  }

  &__AliasCodeWrapper {
    background: $color__orange;
  }

  &__Tip {
    @include style__text--small;
    @include font__bold;
  }

  &__AliasCode {
    @include style__h3;
    color: $color__white;
    letter-spacing: 0.1rem;
  }
}
