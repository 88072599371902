@import '../../styles/_shared';

.RedeemForm {
  position: relative;
  min-height: 40px;

  &__Busy {
    @include util__absolute-coverage;
    z-index: $z__set--exposed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color__white, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__ModalTitle {
    @include style__h3;
    margin-bottom: 20px;
  }

  &__ModalMessage {
    @include style__text;
  }

  &__ModalButton {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
