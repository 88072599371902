@import '../../styles/_shared';

.OfferSlider {
  position: relative;
  padding: 65px 0;
  font-size: 0;

  @include md-down {
    padding: 45px 0 25px;
  }

  &__Header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @include sm-down {
      display: block;
    }
  }

  &--OnlyArrows &__Header {
    @include sm-down {
      display: flex;
    }
  }

  &__Headline {
    &--Big {
      @include style__h2;
    }

    &--Small {
      @include style__h3;
    }
  }

  &__Navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include sm-down {
      margin-top: 20px;
      justify-content: space-between;
    }

    &--OnlyArrows {
      @include xs {
        justify-content: flex-end;
      }
    }
  }

  &__Arrows {
    display: flex;
    align-items: center;
  }

  &__Arrow {
    &--Left {
      margin-left: 40px;

      @include xs {
        margin-left: 20px;
      }
    }

    &--Right {
      margin-left: 20px;

      @include xs {
        margin-left: 10px;
      }
    }
  }

  &__Viewport {
    width: calc(100% + 40px);
    overflow: visible;
  }

  &__Shifter {
    width: 9999999px;
    transition: margin 0.3s ease-in-out;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
  }
}
