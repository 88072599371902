@import '../../styles/_shared';

.ProgressBar {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 6px;
  background: $color__gray--bg;

  &__Progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 6px;
    width: 100%;
    background: $color__orange;
    transition: transform 0.5s ease-in-out;
    transform-origin: left center;
  }
}
