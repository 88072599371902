@import '../../../styles/_shared';

.BigLabel {
  @include style__text;
  @include font__bold;
  margin: 25px 0 -15px 15px;

  @include xs {
    margin-left: 10px;
  }
}
