@import '../../styles/_shared';

.PictureGallery {
  @include util__absolute-coverage();
  overflow: hidden;
  background: $gradient__orange--horizontal;

  &__Buttons {
    position: absolute;
    z-index: $z__raise--medium;
    right: 40px;
    bottom: 40px;
    display: flex;
    align-items: center;
    font-size: 0;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out, transform 0.3s ease-out;

    &--Active {
      pointer-events: all;
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    @include xs {
      right: 20px;
      bottom: 20px;
    }
  }

  &__Button {
    margin-left: 20px;
    flex: none;

    @include xs {
      margin-left: 10px;
    }
  }

  &__Image {
    @include util__absolute-coverage();
    z-index: $z__raise--low;
    object-fit: cover;
  }

  &__Transition {
    &--Right-enter-active,
    &--Right-exit-active,
    &--Left-enter-active,
    &--Left-exit-active {
      transition: transform 0.4s ease-in-out;
    }

    &--Right {
      &-enter {
        transform: translateX(100%);
      }

      &-enter-active {
        transform: translateX(0);
      }

      &-exit {
        transform: translateX(0);
      }

      &-exit-active {
        transform: translateX(-100%);
      }
    }

    &--Left {
      &-enter {
        transform: translateX(-100%);
      }

      &-enter-active {
        transform: translateX(0);
      }

      &-exit {
        transform: translateX(0);
      }

      &-exit-active {
        transform: translateX(100%);
      }
    }
  }
}
