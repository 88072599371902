@import '../../styles/_shared';

.Index {
  padding: 0 0 60px;
  overflow-x: hidden;

  &__Main {
    padding: 80px 0 100px;
    position: relative; // For loading overlay

    @include xs {
      padding: 40px 0 60px;
    }
  }

  &__TopLinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__ProfileLink {
    margin-left: 40px;

    @include xs {
      margin-left: 20px;
    }
  }

  &__Headline {
    @include style__h2;
    margin: 0;
  }

  &__Intro {
    @include style__h4;
    margin: 15px 0 0;
    color: $color__gray--text;
  }


  &__FilterNoResults {
    @include style__h4;
    display: block;
    width: auto;
    margin-top: 40px;
    padding: 40px 0;
    text-align: center;
  }

  &__FilterError {
    @include style__h4;
    display: block;
    width: auto;
    margin-top: 40px;
    padding: 40px 0;
    text-align: center;
  }

  &__FilterResults {
    padding-top: 0;
  }
}
