@use 'sass:math';
@import '../../styles/_shared';

.CircleSpinner {
  &__SpinningCircle {
    stroke-dasharray: math.div(302 * 3, 4);
    stroke-width: 3;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke: $color__orange;
    fill: none;
    transform-origin: center center;
    transition: stroke-dasharray 0.4s ease-in-out;
    animation: CircleSpinner__Circle--Indefinite 1s linear infinite;
    transition-delay: 0.2s;

    &--Complete {
      stroke-dasharray: 302;
      transition-delay: 0s;
    }
  }

  &__DoneCircle {
    fill: $color__orange;
    z-index: $z__raise--low;
    transform-origin: center center;
    transform: scale(0, 0);
    transition: transform 0.4s ease-in-out;
    transition-delay: 0.2s;
    visibility: hidden;

    &--Complete {
      transform: scale(1, 1);
      transition-delay: 0s;
      visibility: visible;
    }
  }

  &__Tick {
    stroke-width: 4;
    stroke-linecap: round;
    stroke: $color__white;
    stroke-dasharray: 90;
    stroke-dashoffset: 90;
    transition: stroke-dashoffset 0.4s ease-in-out;
    transition-delay: 0s;
    visibility: hidden;

    &--Complete {
      transition-delay: 0.2s;
      stroke-dashoffset: 0;
      visibility: visible;
    }
  }
}

@keyframes CircleSpinner__Circle--Indefinite {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
