@import '../../styles/_shared';

.Onboarding {
  &__Title {
    @include style__h1;
    color: $color__white;
    margin: 0 0 25px;
    max-width: 650px;
  }

  &__Intro {
    @include style__h4;
    color: $color__white;
    margin: 0;
    max-width: 400px;
  }

  &__Wizard {
    padding: 80px 0;
    background: $color__gray--bg;

    @include md-down {
      padding: 40px 0;
    }

    @include xs {
      padding: 15px 0;
    }
  }
}
