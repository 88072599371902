@import '../../styles/_shared';

.OfferGrid {
  padding: 65px 0;
  font-size: 0;

  @include md-down {
    padding: 35px 0;
  }

  &__Viewport {
    width: calc(100% + 40px);
    overflow: visible;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include xs {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}
