@import '../../styles/_shared';

.MenuButton {
  @include style__text;
  display: inline-block;
  position: relative;
  color: $color__gray--text;
  text-align: center;
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    display: block;
    background: $color__orange;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -11px;
    height: 4px;
    transform-origin: center bottom;
    transform: scaleY(0);
    transition: transform 0.1s ease-in-out;

    @include sm-up {
      bottom: -22px;
      height: 6px;
    }

    @include md-up {
      bottom: -26px;
    }
  }

  &:hover,
  &--Active {
    color: $color__black--text;

    &::after {
      transform: scaleY(1);
      display: block;
    }
  }

  &--Active {
    @include font__bold;
  }

  &__Badge {
    @include font__bold;
    font-size: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    vertical-align: 1px;
    border-radius: 50%;
    background: $color__orange;
    color: $color__white;

    @include sm-up {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
  }
}
