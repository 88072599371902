@import '../../styles/_shared';

.IconButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: 0;
  cursor: pointer;
  width: auto;
  display: inline-flex;
  align-items: center;
  background: none;
  transition: opacity 0.1s ease-in-out;
  color: $color__orange;
  flex-direction: row;
  padding: 0;
  text-decoration: none;

  &--LabelPosition-LEFT {
    flex-direction: row-reverse;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  @include noTouch {
    &:hover {
      opacity: 0.9;
    }
  }

  &__Label {
    @include style__text;
    margin: 0 0 0 15px;
    text-decoration: underline;
  }

  &--LabelPosition-LEFT &__Label {
    margin: 0 15px 0 0;
  }
}
