@import '../../../styles/_shared';

.OnboardingQuestion {
  padding: 0 0 30px;

  @include xs {
    padding: 0 0 15px;
  }

  &__Question {
    @include style__h4;
    max-width: 70%;
    padding: 0 30px 30px;

    @include md-down {
      max-width: none;
      padding-top: 20px;
    }

    @include xs {
      padding: 15px 15px 30px;
    }
  }

  &__Content {
    padding: 0 30px;

    @include xs {
      padding: 0 15px;
    }

    &--FullWidth {
      padding: 0 !important;
    }
  }
}
