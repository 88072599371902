@import '../../styles/_shared';

.Profile {
  position: relative;

  &__Main {
    padding: 60px 0 140px;

    @include md-down {
      padding: 30px 0 60px;
    }
  }

  &__PersonalData {
    padding: 25px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include md-down {
      display: block;
    }
  }

  &__Information,
  &__Interests {
    width: 45%;
    padding-bottom: 70px;

    @include md-down {
      width: 100%;
    }
  }

  &__Headline {
    @include style__h2;
    margin-bottom: 40px;

    @include md-down {
      margin-bottom: 20px;
    }
  }

  &__Text {
    @include style__text;
    margin-bottom: 30px;
  }

  &__SavePersonalData {
    padding: 30px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px $color__gray--border-light solid;

    @include md-down {
      border-top: none;
      padding: 0;
      flex-wrap: wrap;
    }

    @include xs {
      justify-content: center;
    }
  }

  &__Validation {
    @include style__text--small;
    color: $color__gray--text;
    margin-right: 25px;

    @include md-down {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }

  &__Tooltip {
    display: inline-block;
    margin-left: 5px;
  }

  &__InterestsInvalid {
    @include style__text--label;
    color: $color__orange;
    margin-top: 25px;
  }

  &__Activations {
    background: $color__gray--bg;
    padding: 140px 0;

    @include md-down {
      padding: 60px 0;
    }
  }

  &__Activation {
    display: block;
    background: $color__white;
    padding: 20px 30px;
    margin: 15px 0;

    @include md-down {
      padding: 15px 20px;
    }
  }

  &__ActivationPartner {
    @include style__text--small;
    @include font__bold;
    color: $color__gray--text;
  }

  &__ActivationOffer {
    margin: 5px 0 15px;
  }

  &__ActivationDetails {
    @include style__text;
  }
}
