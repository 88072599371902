@import '../../styles/_shared';

.AppTeaser {
  background: $color__gray--bg;

  &__Container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include md-down {
      display: block;
      position: relative;
    }
  }

  &__ContentWrapper {
    width: 50%;
    flex: 1 1 auto;
    padding: 125px 100px 125px 0;

    @include md-down {
      padding: 80px 0;
      width: 60%;
    }

    @include sm-down {
      width: 100%;
    }

    @include xs {
      padding: 40px 0;
    }
  }

  &__Title {
    @include style__h2;
    margin-bottom: 20px;
  }

  &__Text {
    @include style__text;
    margin-bottom: 30px;
  }

  &__Download {
    @include style__h4;
    margin-bottom: 20px;
  }

  &__Badges {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__Badge {
    display: block;

    &--GooglePlay {
      margin-right: 20px;

      @include xs {
        margin-right: 10px;
      }
    }
  }

  &__BadgeImage {
    height: 50px;
    width: auto;

    @include xs {
      height: 40px;
    }
  }

  &__ImageWrapper {
    width: 35%;
    padding-top: 70px;
    flex: none;
    font-size: 0;

    @include md-down {
      position: absolute;
      bottom: 0;
      padding-top: 0;
      height: 90%;
      right: -5%;
    }

    @include sm-down {
      display: none;
    }
  }

  &__Image {
    width: 100%;
    height: auto;

    @include md-down {
      width: auto;
      height: 100%;
    }
  }
}
