@import '../../styles/shared';

.Overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $z__set--exposed;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // Default
  opacity: 0;
  visibility: hidden;
  padding-top: calc(max(100px, 25vh));

  &--Fixed {
    position: fixed;
  }

  &--In {
    opacity: 1;
    visibility: visible;
  }

  &--Out {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.2s;
  }

  &__Window {
    width: 550px;
    max-width: 90vw;
    background: $color__white;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    padding: 40px;
    // Default
    opacity: 0;
    visibility: hidden;
    //max-height: 90vh;

    @include xs {
      padding: 20px;
    }

    &--In {
      opacity: 1;
      visibility: visible;
      transform: translateZ(0);
      transition-delay: 0.2s;
    }

    &--Out {
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
    }

    &--Rounded {
      border-radius: 8px;
    }
  }

  &__Close {
    position: absolute;
    right: 40px;
    top: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    font-size: 0;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    z-index: $z__raise--low;

    @include xs {
      right: 20px;
      top: 20px;
    }

    @include noTouch {
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
