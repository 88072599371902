@import '../../../styles/_shared';

.OnboardingNavigation {
  display: flex;
  align-items: center;
  justify-content: stretch;

  &__Orientation {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: stretch;

    @include xs {
      display: block;
      margin-right: 20px;
    }
  }

  &__Index {
    @include style__text--small;
    @include font__bold;
    color: $color__gray--text;
    flex: none;
    margin-right: 25px;

    @include xs {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &__ProgressBar {
    flex: 1 1 auto;
  }

  &__Button {
    margin-left: 20px;
    flex: none;
    width: 40px;
    height: 40px;

    @include xs {
      margin-left: 10px;
    }
  }
}
