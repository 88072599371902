@mixin touch() {
  body.Context__Touch & {
    @content;
  }
}

@mixin noTouch() {
  body.Context__NoTouch & {
    @content;
  }
}
