.ViewSwitch {
  &__Transition {
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }
  }
}
