@import '../../styles/shared';

.ArticleContent {
  position: relative;
  min-height: 40px;

  &__Busy {
    @include util__absolute-coverage;
    z-index: $z__raise--low;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color__white, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__PreviewMessage {
    margin-bottom: 40px;
  }
}
