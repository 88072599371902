@import '../../styles/_shared';

.Button {
  @include style__text--button;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  max-width: 270px;
  border: none;
  background: none;
  text-decoration: none;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.1s ease-in-out, background 0.1s ease-in-out, color 0.1s ease-in-out;
  text-align: center;
  position: relative;

  @include xs {
    max-width: none;
  }

  &--FullRound {
    border-radius: 1000px;
  }

  &--FullWidth {
    max-width: 100%;
  }

  &--AutoWidth {
    width: auto;
    padding: 14px 20px;
  }

  &--HasIcon {
    padding: 14px 40px;
  }

  &--SlimOnMobile {
    @include xs {
      padding: 8px;
    }
  }

  &:focus {
    outline: none;
  }

  @include noTouch {
    &:hover {
      opacity: 0.9;
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &--RowStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--RowCenter {
    border-radius: 0;
  }

  &--RowEnd {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

  }

  &--Style {
    // Font color
    &-ORANGE_FILL,
    &-WHITE_OUTLINE {
      color: $color__white;
    }

    &-ORANGE_OUTLINE,
    &-WHITE_FILL {
      color: $color__orange;
    }

    // Background
    &-ORANGE_FILL {
      background: $color__orange;
    }

    &-WHITE_FILL {
      background: $color__white;
    }

    // Border
    &-ORANGE_FILL,
    &-ORANGE_OUTLINE {
      border: 2px $color__orange solid;
    }

    &-WHITE_FILL,
    &-WHITE_OUTLINE {
      border: 2px $color__white solid;
    }
  }

  &__Icon {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
}
