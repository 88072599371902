@import '../../styles/_shared';

.RecommendationProgram {
  background: $color__blue;
  padding: 65px 0 80px;

  @include xs {
    padding: 40px 0;
  }

  &__Wrapper {
    display: flex;
    align-items: center;
    justify-content: stretch;

    @include sm-down {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  &__Content {
    padding-right: 10%;
    flex: 1 1 auto;

    @include sm-down {
      padding-right: 0;
    }
  }

  &__Title {
    @include style__h2;
    color: $color__white;
    margin-bottom: 25px;

    @include sm-down {
      display: none;
    }
  }

  &__Text {
    @include style__text;
    color: $color__white;
    margin-bottom: 30px;
  }

  &__Logo {
    width: 300px;
    height: auto;
    flex: none;

    @include sm-down {
      width: 300px;
      max-width: 100%;
      margin-bottom: 40px;
    }
  }
}
