@import './inputs';

.FullwidthCheckbox {
  @include style__text;
  color: $color__black--text;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0 10px;
  border-bottom: 1px $color__gray--border-light solid;

  &--Ticked &__Tick {
    opacity: 1;
    transform: scale(1, 1);
  }

  @include noTouch {
    &:hover {
      background: rgba($color__gray--bg, 0.2);
    }
  }

  &__Indicator {
    flex: none;
    width: 28px;
    height: 28px;
    border: 2px $color__orange solid;
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Tick {
    opacity: 0;
    transform: scale(0, 0);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &--Disabled,
  &--Readonly {
    color: $color__gray--text;
    pointer-events: none;
  }

  &--Disabled &__Indicator {
    opacity: 0.3;
  }
}
