@import '../../styles/_shared';

.OfferPanel {
  z-index: $z__revert;
  position: relative;
  background: $color__white;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 440px;

  @include md-down {
    height: auto;
    display: block;
  }

  &--Article {
    @include lg {
      width: 65%;
    }
  }

  &--ExternalMG {
    @include lg {
      width: 85%;
    }
  }

  &__MediaWrapper {
    flex: 1 1 auto;
    width: 65%;
    position: relative;

    @include md-down {
      width: 100%;
      height: 400px;
    }

    @include sm {
      height: 300px;
    }

    @include xs {
      width: 100%;
      padding-bottom: 56.25%;
      height: auto;
    }
  }

  &__DataWrapper {
    flex: 1 1 auto;
    width: 35%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    @include md-down {
      width: 100%;
      flex-direction: row;
    }

    @include sm-down {
      display: block;
    }

    @include xs {
      padding: 20px;
    }
  }

  &__Data {
    margin: -20px 0;

    @include sm-down {
      margin: -20px 0 0;
    }
  }

  &__Highlight {
    position: relative;
    margin: 20px 0;
  }

  &__Redeem {
    @include md {
      flex: none;
      width: 250px;
      margin-left: 50px;
    }

    @include sm-down {
      margin-top: 35px;
    }
  }
}
