@import '../../styles/_shared';

.AccordionItem {
  border-bottom: 1px $color__gray--border-light solid;
  padding-top: 5px;

  &__Toggle {
    @include style__text--big;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;

    &:focus {
      outline: none;
    }
  }

  &--Open &__Toggle {
    @include font__bold;
  }

  &__Question {
    padding-right: 40px;
    text-align: left;
  }

  &__Caret {
    transition: transform 0.5s ease-in-out;
  }

  &--Open &__Caret {
    transform: rotateZ(180deg);
  }

  &__Content {
    box-sizing: border-box;
    padding: 20px 30% 40px 0;

    @include md-down {
      padding-right: 40px;
    }

    @include xs {
      padding: 15px 0 30px;
    }
  }
}
