@import '../../../styles/shared';

.TourIntro {
  &__Container {
    display: flex;
    flex-direction: column;
  }

  &__Headline {
    @include style__h3;
  }

  &__Content {
    @include style__text;
    margin-top: 27px;
    margin-bottom: 33px;
  }

  &__Buttons {
    display: flex;
    flex-direction: row;
    gap: 39px;
  }
}
