@import '../../styles/_shared';

.Tooltip {
  position: relative;

  &__Icon {
    cursor: pointer;
  }

  &__Content {
    @include style__text--tooltip;
    position: absolute;
    top: -5px;
    background: $color__gray--strong;
    z-index: $z__raise--high;
    border-radius: 8px;
    padding: 10px 15px;
    color: $color__white;
    min-width: 200px;
    max-width: 300px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    box-sizing: border-box;

    @include md-up {
      max-width: 550px;
    }

    @include xs {
      position: fixed;
      left: 15px;
      right: 15px;
      max-width: calc(100vw - 30px);
    }

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }
}
