@import '../../styles/_shared';

.Modal {
  &__Title {
    @include style__h3;
    margin-bottom: 20px;
  }

  &__Message {
    @include style__text;
  }

  &__Button {
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
