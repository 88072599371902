@import '../../../../styles/_shared';

.OnboardingInterests {
  margin-bottom: 50px;

  &__Intro {
    @include style__text;
    margin: -15px 0 20px;
  }

  &__Buttons {
    margin: -7px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include xs {
      margin: -3px;
    }
  }

  &__Button {
    margin: 7px;

    @include xs {
      margin: 3px;
    }
  }
}
