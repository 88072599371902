@import '../../styles/_shared';

.Container {
  max-width: 1024px;
  padding: 0 15px;
  margin: 0 auto;

  @include md {
    max-width: 720px;
  }

  @include sm {
    max-width: 540px;
  }

  @include xs {
    max-width: 100%;
    width: 100%;
  }
}
