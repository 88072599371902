@import '../../styles/_shared';

.OfferTile {
  @include style__text;
  position: relative;
  background: $color__white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  transition: box-shadow 0.1s ease-in-out;

  &__TourInsets {
    background: transparent;
    pointer-events: none;
    position: absolute;
    inset: 0 20px !important;

    @include xs {
      margin: 10px 0 !important;
    }
  }

  &--GRID {
    @include xs {
      width: 100% !important;
      margin: 10px 0 !important;
    }
  }

  &:not(&--Expired) {
    @include noTouch {
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      }
    }
  }

  &__Badge {
    @include style__text;
    position: absolute;
    left: -5px;
    top: 20px;
    color: $color__white;
    text-transform: uppercase;
    background: $color__orange;
    padding: 7px 15px;

    @include xs {
      padding: 5px 10px;
      top: 15px;
    }

    // Orange triangle
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 100%;
      width: 0;
      height: 0;
      border-top: 5px solid $color__orange;
      border-left: 5px solid transparent;
    }
  }

  &--Expired &__Badge {
    background: $color__gray--text;

    &::after {
      border-top-color: $color__gray--text;
    }
  }

  &__ImageWrapper {
    position: relative;
    flex: none;
    width: 100%;
    padding-bottom: 56.25%; // Ratio 16:9
    background: $gradient__orange--horizontal;
  }

  &__Image {
    @include util__absolute-coverage;
    object-fit: cover;
    overflow: hidden;
  }

  &__Content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    padding: 25px 30px;

    @include md-down {
      padding: 20px 30px;
    }

    @include xs {
      padding: 20px;
    }
  }

  &__Site {
    @include style__text--small;
    color: $color__gray--text;
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  &__Caption {
    @include style__text--small;
    @include font__bold;
    color: $color__gray--text;
    margin-bottom: 5px;
  }

  &__Title {
    @include style__h4;
    color: $color__black--text;
    margin-bottom: 30px;
  }

  &__Excerpt {
    @include style__text;
    color: $color__black--text;
    flex: 1 1 auto;
    margin-bottom: 30px;
  }

  &__LearnMore {
    @include style__text;
    color: $color__orange;
    text-decoration: underline;
    text-align: right;
  }

  &__RemoveBookmark {
    @include style__text;
    color: $color__gray--text;
    text-align: right;
  }

  // Reduce opacity for expired offers
  &--Expired &__ImageWrapper,
  &--Expired &__Partner,
  &--Expired &__Title,
  &--Expired &__Excerpt {
    opacity: 0.4;
  }
}
