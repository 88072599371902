@import '../../styles/_shared';

.OfferBody {
  padding: 0 0 125px;

  @include md-down {
    padding-bottom: 80px;
  }

  &__Wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;

    @include md-down {
      flex-direction: column-reverse;
    }
  }

  &__Main {
    width: 65%;

    &--Wider {
      width: 85%;
    }

    @include md-down {
      width: 100% !important;
    }
  }

  &__Sidebar {
    width: 35%;

    @include md-down {
      width: 100%;
      margin-bottom: 50px;
      margin-top: -30px;
    }
  }

  &__Conditions {
    background: $color__gray--bg;
    padding: 30px;
  }

  &__ConditionsHeadline {
    @include style__h4;
    margin-bottom: 25px;
  }

  &__DescriptionLong {
    padding-bottom: 50px;

    @include lg {
      padding-right: 100px;
    }
  }

  &__DescriptionPlainText {
    @include style__h4;
  }

  &__ArticleContent {
    padding-bottom: 50px;
    position: relative;
    min-height: 150px;
  }

  &__Partner {
    padding-bottom: 25px;

    @include lg {
      padding-right: 100px;
    }
  }

  &__PartnerHeadline {
    @include style__h2;
    margin-bottom: 25px;
  }

  &__Data {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    flex-wrap: wrap;

    @include sm-down {
      display: block;
    }
  }

  &__DataItem {
    flex: 1 1 auto;
    padding-right: 50px;
    margin-bottom: 25px;

    @include md-down {
      padding-right: 25px;
    }

    @include sm-down {
      padding-right: 0;
    }
  }

  &__DataItemHeadline {
    @include style__text;
    @include font__bold;
  }

  &__DataItemContent {
    @include style__text;
  }

  &__PartnerLogo {
    margin-top: 10px;
    display: block;
    width: auto;
    height: auto;
    max-width: 120px;
    max-height: 120px;
  }
}
