@import '../../styles/shared';

.OfferHeader {
  &__Wrapper {
    &::before {
      z-index: $z__revert;
      content: '';
      position: absolute;
      inset: 0;
      background: $gradient__orange--horizontal;
    }

    padding-bottom: 250px;
    padding-top: 140px;
    min-height: 570px;
    display: flex;
    align-items: flex-end;
    justify-content: stretch;
    position: relative;

    @include sm {
      height: auto;
      padding-bottom: 200px;
    }

    @include sm-down {
      min-height: auto;
    }

    @include xs {
      height: auto;
      padding-top: 120px;
      padding-bottom: 180px;
    }
  }

  &__Back {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;

    @include xs {
      top: 30px;
    }
  }

  &__Content {
    flex: 1 1 auto;
    position: relative;
  }

  &--Dummy &__Content {
    background: $color__gray--bg;
  }

  &__Title {
    @include style__h1;
    color: $color__white;
    margin-bottom: 20px;
  }

  &__Excerpt {
    @include style__h4;
    color: $color__white;
  }

  &__Panel {
    margin-top: -200px;
    position: relative;
    z-index: $z__raise--low;
    padding-bottom: 80px;

    @include sm-down {
      margin-top: -150px;
    }
  }
}
