@import './inputs';

.Checkbox {
  @include style__text;
  color: $color__black--text;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 25px;
  padding-left: 15px;

  @include xs {
    padding-left: 10px;
  }

  & + & {
    // Two checkboxes on top of each other have smaller gap
    margin-top: 15px;
  }

  &--Ticked &__Tick {
    opacity: 1;
    transform: scale(1, 1);
  }

  &__Indicator {
    flex: none;
    width: 26px;
    height: 26px;
    border: 1px $color__gray--border solid;
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
    transition: border-color 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include noTouch {
      &:hover {
        border-color: $color__orange;
      }
    }
  }

  &__Tick {
    margin-top: 2px;
    opacity: 0;
    transform: scale(0, 0);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &--Disabled,
  &--Readonly {
    color: $color__gray--text;
    pointer-events: none;
  }
}
