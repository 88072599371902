@import '../../../styles/shared';

.ContractSelectItem {
  padding: 25px 30px;
  position: relative;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  background: $color__white;
  color: $color__black--text;
  cursor: pointer;

  @include xs {
    padding: 15px;
  }

  @include noTouch {
    &:hover {
      background: rgba($color__gray--bg, 0.2);
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 30px;
    height: 1px;
    right: 0;
    background: $color__gray--bg;
    opacity: 1;
    transition: opacity 0.1s ease-in-out;

    @include xs {
      left: 15px;
    }
  }

  &--Picked {
    background: $color__orange;
    color: $color__white;

    @include noTouch {
      &:hover {
        background: $color__orange;
      }
    }

    &::after {
      opacity: 0;
    }
  }

  &__Name {
    @include style__h4;
    margin-bottom: 15px;
  }

  &__Details {
    @include style__text;
  }

  &__Check {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 30px;
    border: 2px $color__orange solid;
    border-radius: 50%;
    background: $color__white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;

    @include xs {
      right: 15px;
      top: 15px;
    }
  }

  &__Tick {
    font-size: 0;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &--Picked &__Tick {
    opacity: 1;
    transform: scale(1);
  }
}
