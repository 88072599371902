@import '../../styles/_shared';

// Default styles for random wysiwyg content
.WysiwygStyling {
  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  h1 {
    @include style__h1;
    margin: 60px 0 40px;
  }

  h2 {
    @include style__h2;
    margin: 60px 0 40px;
  }

  h3 {
    @include style__h3;
    margin: 40px 0 30px;
  }

  h4 {
    @include style__h4;
    margin: 30px 0 20px;
  }

  p {
    @include style__text;
    margin: 20px 0;
  }

  a {
    color: $color__orange;
    text-decoration: underline;
    transition: colors 0.1s ease-in-out;

    @include noTouch {
      &:hover {
        color: $color__orange--hover;
      }
    }
  }

  ul,
  ol {
    @include style__text;
    margin: 20px 0;
    padding: 0;
  }

  ol {
    li {
      margin: 15px 0 15px 20px;
      padding-left: 5px;
    }
  }

  ul {
    list-style: none;

    li {
      background-image: url('../../images/listitem.svg');
      background-position: left top 0.3rem;
      background-size: 9px;
      background-repeat: no-repeat;
      display: block;
      margin: 15px 0;
      padding-left: 25px;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .WysiwygButton {
    @include style__text--button;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: auto;
    padding: 14px 25px;
    border: none;
    background: $color__gray--border;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
    text-align: center;
    color: $color__white;

    @include xs {
      width: 100%;
    }

    &:focus {
      outline: none;
    }

    @include noTouch {
      &:hover {
        opacity: 0.9;
        color: $color__white;
      }
    }
  }
}
