@import '../../styles/_shared';

.RedeemExternalMg {
  position: relative;
  min-height: 40px;

  &__Step {
    border-radius: 50%;
    height: 40px;
    aspect-ratio: 1;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    font-size: 16px;
    color: $color__white;

    margin-right: 30px;
    background: $color__orange;
  }

  &__Busy {
    @include util__absolute-coverage;
    z-index: $z__set--exposed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color__white, 0.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__ModalTitle {
    @include style__h3;
    margin-bottom: 28px;
  }

  &__ModalSuccess {
    &__Heading {
      margin-top: 36px;
    }

    &__Segment {
      width: 100%;
      max-width: 400px;
      margin-top: 28px;
      & > span {
        @include style__text;
      }
    }

    &__End {
      width: 100%;
      max-width: 400px;
      margin-top: 100px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__ModalMessages {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 20px;
      bottom: 20px;
      width: 19px;
      border-right-color: $color__orange;
      border-right-style: dotted;
      border-right-width: 2px;
    }

    display: flex;
    flex-direction: column;
    gap: 20px
  }

  &__ModalMessage {
    position: relative;
    display: flex;
    flex-direction: row;

    &__TooltipText {
      width: 278px;
    }

    & > span {
      @include style__text;
    }
  }

  &__Notice {
    margin-top: 35px;
    padding: 18px 10px 25px 20px;
    border-radius: 8px;
    background-color: $color__gray--bg;
    display: flex;
    flex-direction: row;

    &__Text {
      padding-left: 18px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      & > span {
        @include style__text--small;
      }
    }
  }

  &__ModalButton {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px
  }
}
