@import '../../styles/shared';
@import './inputs/inputs';

.Dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  font-size: 16px;

  // Header
  // ===========================================================================

  &__Header {
    flex: 1;
    position: relative;
    padding: 15px 36px 15px 20px;
    background-color: $color__white;
    border: 1px solid $color__gray--border-light;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      position: relative;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__Caret {
    @include transition__fade-out(0.2s, 0s);
    right: 20px;
  }

  &__Clear {
    right: 33px;
    width: 36px;
    height: 36px;
    padding: 12px;
  }

  // Select
  // ===========================================================================

  &__Select {
    @include transition__fade-out(0.2s, 0s);
    z-index: $z__raise--low;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0 0 20px;
    background-color: $color__white;
    border-radius: 8px;
    box-shadow: 0 5px 25px 0 rgba($color__black, 0.1);
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
  }

  // Item
  // ===========================================================================

  &__Item {
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 0;
    cursor: pointer;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color__gray--border-light;
    }

    &:hover {
      color: $color__orange;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 18px 0 0;
      fill: $color__orange;
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;

      &:checked + .Dropdown__Item-checkmark {
        @include transition__fade-in(0.2s, 0s);
        background-color: $color__orange;
      }
    }

    // Item Checkmark
    // ===========================================================================

    &-checkmark {
      @include transition__fade-out(0.2s, 0s);
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: auto;
      background-color: $color__white;
      border-radius: 100%;
      border: 2px solid $color__orange;

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 6px;
        border-bottom: 1px solid #ffffff;
        border-left: 1px solid #ffffff;
        transform: rotate(-45deg) translate(-1px, 7px);
      }
    }

    // Modifier
    // ===========================================================================

  }

  &--Gray & {
    &__Header {
      background-color: $color__gray--bg;
    }
  }

  &--Open & {
    &__Select {
      @include transition__fade-in(0.2s, 0s);
      opacity: 1;
      visibility: visible;
    }

    &__Caret {
      @include transition__fade-in(0.2s, 0s);
      transform: rotate(180deg) translateY(5px);
    }
  }
}
