@import '../../styles/shared';

.Hero {
  background: $gradient__orange--horizontal;
  position: relative;

  &--Dummy {
    background: $color__gray--bg;
  }

  &__Image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $z__raise--low;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__Wrapper {
    height: 650px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 80px 25% 80px 0;
    position: relative;
    z-index: $z__raise--low;

    @include md {
      height: 480px;
      padding: 60px 20% 60px 0;
    }

    @include sm {
      height: 400px;
      padding: 40px 0;
    }

    @include xs {
      height: auto;
      padding: 120px 0 30px;
    }
  }

  &--Slim &__Wrapper {
    height: auto;
    padding: 160px 0 80px;

    @include xs {
      padding: 120px 0 30px;
    }
  }
}
