@import '../../styles/shared';

.Flyout {
  position: absolute;
  width: 0;
  height: 0;
  z-index: $z__set--overlay;
  display: flex;

  &--top-left {
    top: 0;
    left: 0;
    flex-direction: column;
  }

  &--top-center {
    top: 0;
    left: 50%;
    flex-direction: column;
  }

  &--top-right {
    top: 0;
    right: 0;
    flex-direction: column;
  }

  &--center-left {
    top: 50%;
    left: 0;
  }

  &--center-right {
    top: 50%;
    right: 0;
  }

  &--bottom-left {
    bottom: 0;
    left: 0;
    flex-direction: column;
  }

  &--bottom-center {
    bottom: 0;
    left: 50%;
    flex-direction: column;
  }

  &--bottom-right {
    bottom: 0;
    right: 0;
    flex-direction: column;
  }
}

.FlyoutContainer {
  pointer-events: none;
  position: absolute;
  align-self: center;
  opacity: 0;
  z-index: $z__set--overlay;

  transition: none;
  transform: translateZ(0);
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 50%));

  &--start {
    align-self: flex-start;
  }

  &--center {
    align-self: center;
  }

  &--end {
    align-self: flex-end;
  }

  &--active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity $dt__quick ease-in (2 * $dt__quick);
  }
}
